<template>
    <div class="card">
        <div class="header">
            <h3><slot name="header" /></h3>
        </div>

        <div class="content">
            <p><slot name="content" /></p>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    computed: {},
    methods: {},
    mounted() {},
}
</script>

<style lang="scss" scoped>
.card {
    background-color: $color-cosmiclatte;
    border-radius: 6px;
    padding: $spacer;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        'header'
        'content';

    &:before {
        background-image: url('../assets/icons/icon-check.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 14px 14px;
        background-color: $color-maroon;
        width: 40px;
        height: 40px;
        content: '';
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .header {
        grid-area: header;

        h3 {
            color: $color-maroon;
            font-size: rem(23px);
            font-size: $semi-bold;
            text-align: center;
            margin: $spacer 0 0 0;
        }
    }
    .content {
        grid-area: content;

        p {
            color: $color-tangerine;
            font-size: rem(16px);
            line-height: 1.5;
        }
    }

    @include tablet {
        width: 100%;
    }
}
</style>
