<template>
    <div class="appointment">
        <div class="container titles">
            <div>
                <h1 v-if="language === 'en'">{{ h1EN }}</h1>
                <h1 v-else>{{ h1 }}</h1>

                <div class="row">
                    <label for="location">{{ locationLabel }}</label>

                    <label
                        class="custom-radio-container"
                        v-for="(location, i) in $store.state.locations"
                        :key="location.id"
                    >
                        <span>{{ location.locatienaam }}</span>
                        <input
                            type="radio"
                            checked="checked"
                            name="radio"
                            @click="setLocation(location.id, i)"
                            :value="location.id"
                            v-model="whichLocation"
                        />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>

            <div>
                <h2 v-if="language === 'en'">{{ h2EN }}</h2>
                <h2 v-else>{{ h2 }}</h2>
            </div>
        </div>

        <div :class="[!whichLocation ? 'disabled' : '', 'container']">
            <div class="calendar">
                <vc-date-picker
                    is-expanded
                    is-inline
                    v-model="date.date"
                    :locale="language == 'en' ? 'en' : 'nl'"
                    :available-dates="new Date()"
                    :min-date="new Date()"
                    :first-day-of-week="2"
                    @dayclick="fetchTimeBlocks($event)"
                />
            </div>

            <div class="time-blocks">
                <ul :class="[{ empty: emptyMessage }, { empty: closedMessage }]">
                    <li
                        v-for="(hour, i) in openingHours"
                        :key="i"
                        @click="setTime(hour, i)"
                        :class="{ active: i === activeTime }"
                    >
                        {{ hour }}
                    </li>
                    <li v-if="emptyMessage">{{ language == 'en' ? emptyEN : empty }}</li>
                    <li v-else-if="closedMessage">{{ language == 'en' ? closedEN : closed }}</li>
                </ul>
                <span class="error color-error" v-if="error">{{ language == 'en' ? timeErrorEN : timeError }}</span>
            </div>

            <Aside :date="date" v-on:emitdate="setFinalDate"></Aside>
        </div>

        <HowDoesItWork :nobtn="true"></HowDoesItWork>
    </div>
</template>

<script>
import { parse, format, eachMinuteOfInterval } from 'date-fns'
import { api } from '@/api'
import Aside from '@/components/Aside.vue'
import HowDoesItWork from '@/components/HowDoesItWork.vue'

export default {
    name: 'Appointment',

    components: {
        Aside,
        HowDoesItWork,
    },

    props: {
        h1: {
            type: String,
            default: 'Kies locaties, datum en tijd',
        },
        h1EN: {
            type: String,
            default: 'Choose location, date and time',
        },
        h2: {
            type: String,
            default: 'Overzicht',
        },
        h2EN: {
            type: String,
            default: 'Overview',
        },
        empty: {
            Type: String,
            default: 'Er zijn geen tijden meer beschikbaar vandaag.',
        },
        emptyEN: {
            Type: String,
            default: 'There are no more times available today.',
        },
        closed: {
            Type: String,
            default: 'Vandaag zijn wij gesloten.',
        },
        closedEN: {
            Type: String,
            default: 'We are closed.',
        },
        timeError: {
            type: String,
            default: 'Hoe laat wilt u komen? Kies een tijd',
        },
        timeErrorEN: {
            type: String,
            default: 'Choosing a time is required',
        },
        locationLabel: {
            type: String,
            default: 'Testlocatie',
        },
        locationLabelEN: {
            type: String,
            default: 'Testlocation',
        },
    },

    data() {
        return {
            error: false,
            activeTime: null,
            emptyMessage: false,
            closedMessage: false,
            unavailableHours: [],
            openingHours: [],
            weekDays: [],
            whichLocation: this.location || null,
            date: {
                location: this.$store.state.date.location,
                date: new Date(),
                selectedTime: '',
            },
        }
    },

    methods: {
        async fetchHours(locationid) {
            try {
                const response = await api.get('/getOpeningHours', {
                    headers: {
                        id: locationid,
                    },
                })
                const result = await response.data

                this.weekDays = result.openingHours
            } catch (error) {
                console.log(error)
            }
        },

        isToday(date) {
            const today = new Date()
            return (
                date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
            )
        },

        nvWeekendMoveDate() {
            if (new Date().getDay() == 6 || new Date().getDay() == 0) {
                let day = new Date()
                let days

                if (day.getDay() == 6) {
                    // saturday
                    days = 7 - day.getDay() + 1
                } else if (day.getDay() == 0) {
                    // sunday
                    days = 7 + day.getDay() - 6
                }

                let nextDay = new Date(day.setDate(day.getDate() + days))
                return nextDay
            } else {
                return new Date()
            }
        },

        async fetchMaxPerTime(date, time) {
            const response = await api.post('/timeblockAmount', {
                date: date,
                time: time,
                location: this.whichLocation,
            })
            return response.data[0].amount
        },

        setTime(hour, i) {
            this.date.selectedTime = hour
            this.activeTime = i

            if (screen.width <= 640) {
                this.$smoothScroll({
                    scrollTo: document.querySelector('aside'),
                    offset: -90,
                })
            }
        },

        setFinalDate(e) {
            this.error = false

            if (!this.date.selectedTime) {
                this.error = true
                return
            }

            this.date.date = this.switchDateOrder(this.formatDate(e.date))
            this.$store.commit('SET_DATETIME', this.date)

            if (this.language == 'en') {
                this.$router.push({ name: 'UserInfo' })
            } else {
                this.$router.push({ name: 'Gegevens' })
            }
        },

        switchDateOrder(date) {
            let a = date.split('/')
            ;[a[0], a[1]] = [a[1], a[0]]

            if (a[0] < 10) {
                a[0] = `0${a[0]}`
            }

            if (a[1] < 10) {
                a[1] = `0${a[1]}`
            }
            return a.join('/')
        },

        enumerateHours(startTime, endTime) {
            if (!startTime || !endTime) return (this.closedMessage = true)

            const start = parse(startTime, 'HH:mm', new Date())
            const end = parse(endTime, 'HH:mm', new Date())

            const dates = eachMinuteOfInterval({ start, end }, { step: 15 }).map(d => format(d, 'HH:mm'))

            return (this.openingHours = dates)
        },

        async fetchTimeBlocks(e) {
            this.date.selectedTime = ''
            this.activeTime = null
            this.openingHours = []
            this.emptyMessage = false
            this.closedMessage = false

            if (!e.id) e = { id: e, weekday: new Date(e).getDay() + 1 }
            let formattedDate
            e.id ? (formattedDate = this.formatDate(e.id)) : (formattedDate = this.formatDate(e))

            this.error = false

            if (e.isDisabled) {
                this.emptyMessage = true
                return
            }

            switch (e.weekday) {
                case 1: // sunday
                    this.enumerateHours(this.weekDays[6].zondag[0], this.weekDays[6].zondag[1])
                    break
                case 2: // monday
                    this.enumerateHours(this.weekDays[0].maandag[0], this.weekDays[0].maandag[1])
                    break
                case 3: // tuesday
                    this.enumerateHours(this.weekDays[1].dinsdag[0], this.weekDays[1].dinsdag[1])
                    break
                case 4: // wednesday
                    this.enumerateHours(this.weekDays[2].woensdag[0], this.weekDays[2].woensdag[1])
                    break
                case 5: // thursday
                    this.enumerateHours(this.weekDays[3].donderdag[0], this.weekDays[3].donderdag[1])
                    break
                case 6: // friday
                    this.enumerateHours(this.weekDays[4].vrijdag[0], this.weekDays[4].vrijdag[1])
                    break
                case 7: // saturday
                    this.enumerateHours(this.weekDays[5].zaterdag[0], this.weekDays[5].zaterdag[1])
                    break
            }

            // this.unavailableHours = []
            this.removeTimesPast(formattedDate)

            // const timeResponse = await api.post('/availableTimes', {
            //     date: this.switchDateOrder(formattedDate),
            //     location: this.whichLocation,
            // })
            // for (let block of timeResponse.data) {
            //     let maxNum = await this.fetchMaxPerTime(this.switchDateOrder(formattedDate), block.time)

            //     if (block.num >= maxNum) {
            //         this.unavailableHours.push(block.time)
            //     }

            //     this.openingHours = this.openingHours.filter(item => !this.unavailableHours.includes(item))
            // }
        },

        formatDate(date) {
            const _date = new Date(date) // yyyy-MM-dd from vCalendar to match date in database
            return _date.getMonth() + 1 + '/' + _date.getDate() + '/' + _date.getFullYear()
        },

        switchDateOrder(date) {
            let a = date.split('/')
            ;[a[0], a[1]] = [a[1], a[0]]

            if (a[0] < 10) {
                a[0] = `0${a[0]}`
            }

            if (a[1] < 10) {
                a[1] = `0${a[1]}`
            }
            return a.join('/')
        },

        removeTimesPast(formattedDate) {
            this.emptyMessage = false
            const currTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
            const currDate = new Date().toLocaleDateString('en-US')

            if (formattedDate === currDate) {
                this.openingHours = this.openingHours.filter(x => {
                    let timeArr = x.split(':').map(v => Number(v))
                    let current = currTime.split(':').map(a => Number(a))

                    let currentTime = new Date(null, null, null, current[0], current[1] + 5)
                    let pastTime = new Date(null, null, null, timeArr[0], timeArr[1])

                    return currentTime < pastTime
                })

                if (this.openingHours.length <= 0) {
                    this.emptyMessage = true
                }
            }
        },

        async setLocation(id, index) {
            console.log('setting location...')
            this.$store.commit('SET_LOCATION', id)
            this.$store.commit('SET_LOCATIONTEXT', this.$store.state.locations[index].adres)
            this.date.location = this.$store.state.locations[index].adres

            await this.fetchHours(id)
            this.fetchTimeBlocks(this.date.date)
            this.removeTimesPast(this.formatDate(this.date.date))
        },
    },

    computed: {
        language() {
            return this.$store.state.language
        },

        location() {
            return this.$store.state.location
        },
    },
}
</script>

<style lang="scss">
.appointment {
    max-width: 1100px;
    width: 100%;
    margin: $gutter * 2 auto;

    @include desktop {
        margin: $gutter auto;
    }

    .container {
        display: grid;
        grid-template-columns: 260px 375px auto 250px;
        grid-gap: $gutter;

        &.disabled {
            opacity: 0.2;
            pointer-events: none;
        }

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $spacer;
        }

        @include mobile {
            grid-template-columns: 1fr;
        }

        @include desktop {
            padding: 0 $spacer;
        }

        &.titles {
            grid-template-columns: 260px auto 250px;

            & > div:first-of-type {
                grid-column: span 2;
            }

            @include desktop {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: $spacer;

                div {
                    display: block;
                }
            }

            @include mobile {
                grid-template-columns: 1fr;
                display: block;
            }

            label {
                font-size: rem(14px);
                font-weight: $semi-bold;
                color: $color-maroon;
                display: block;
                margin-bottom: 3px;
            }

            .custom-radio-container {
                display: inline-block;
                position: relative;
                padding-left: 25px;
                margin-top: 10px;
                margin-right: $gutter;
                margin-bottom: $gutter;
                cursor: pointer;
                font-size: 22px;
                user-select: none;

                &:last-of-type {
                    margin-right: 0;
                }

                @include mobile {
                    margin-bottom: 0;

                    &:last-of-type {
                        margin-bottom: $spacer;
                    }
                }

                span:not(.checkmark) {
                    @extend label;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 19px;
                    width: 19px;
                    border-radius: 50%;
                    border: 1px solid $color-burgundy;

                    &:after {
                        content: '';
                        position: absolute;
                        display: none;
                        left: 6px;
                        top: 3px;
                        width: 3px;
                        height: 7px;
                        border: solid $color-burgundy;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }

                input:checked ~ .checkmark:after {
                    display: block;
                }
            }
        }
    }
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 $gutter;
}

h2 {
    @include mobile {
        display: none;
    }
}

.calendar {
    max-width: 650px;
    width: 100%;
    margin-right: 10px;

    @include desktop {
        grid-column: 1/2;
    }

    @include mobile {
        grid-column: 1;
    }

    .vc-container {
        font-family: $Signika !important;
    }

    .in-prev-month .vc-opacity-0 {
        opacity: 1;
    }

    .vc-rounded-lg {
        border-radius: 0 !important;
    }

    .vc-border {
        border-color: $color-salmon;
        border-radius: 6px !important;
    }
}

.time-blocks {
    background: $color-white;
    width: 100%;
    border: 1px solid $color-salmon;
    padding: $spacer - 4;
    border-radius: 6px;

    @include desktop {
        grid-row: 2;
        grid-column: 1 / 2;
    }

    @include mobile {
        grid-row: auto;
        grid-column: 1;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(13, 1fr);
        grid-auto-flow: column;
        grid-gap: 4px 10px;

        li {
            background: rgba(52, 193, 195, 0.02);
            width: 60px;
            height: $spacer;
            border: 1px solid $color-salmon;
            border-radius: 6px;
            margin-bottom: 4px;
            text-align: center;
            font-size: rem(12px);
            font-weight: $semi-bold;
            color: #82a2b0;
            line-height: 1.7;
            cursor: pointer;
            transition: background 0.2s, color 0.2s, border-color 0.2s;

            @include desktop {
                height: $spacer + 10px;
                line-height: 2.4;
            }

            &:hover {
                background: rgba(52, 193, 195, 0.25);
                transition: background 0.2s;
            }

            &.active {
                background: $color-burgundy;
                color: $color-white;
                border-color: $color-burgundy;
                transition: background 0.2s, color 0.2s, border-color 0.2s;
            }
        }

        &.empty {
            display: block;

            li {
                width: 100%;
                height: auto;
            }
        }
    }
}

.error {
    font-size: rem(14px);
}

cite {
    margin: 0 auto;
    font-weight: $normal;
    color: $color-maroon;
    font-style: normal;
    text-align: center;
    display: block;
    margin-top: $gutter * 3;
    opacity: 0.75;

    @include desktop {
        padding: 0 $spacer;
    }
}
</style>
