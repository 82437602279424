<template>
    <section class="howdoesitwork">
        <h3 v-if="language === 'en'">{{ h3EN }}</h3>
        <h3 v-else>{{ h3 }}</h3>

        <ul v-if="language === 'en'">
            <li :flow="flow" v-for="item in flowEN" :key="item.title">
                {{ item.title }}
                <span v-html="item.sub"></span>
                <span class="extra" v-if="item.extra">{{ item.extra }}</span>
            </li>
        </ul>

        <ul v-else>
            <li :flow="flow" v-for="item in flow" :key="item.title">
                {{ item.title }}
                <span v-html="item.sub"></span>
                <span class="extra" v-if="item.extra">{{ item.extra }}</span>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    name: 'HowDoesItWork',

    props: {
        h3: {
            type: String,
            default: 'Hoe werkt het?',
        },
        h3EN: {
            type: String,
            default: 'How does it work?',
        },
        flow: {
            type: Array,
            default: () => [
                {
                    title: 'Maak een afspraak',
                    sub: 'Kies de gewenste locatie, datum en tijdslot',
                },
                {
                    title: 'Ontvang de email bevestiging',
                    sub: 'U ontvangt een bevestiging per e-mail (check evt. ook uw spamfilter)',
                },
                {
                    title: 'Kom naar de gekozen snelteststraat',
                    sub: 'Kom op tijd, maar maximaal 5 minuten van te voren en volg de aanwijzingen',
                    extra: 'Vergeet uw ID niet mee te nemen',
                },
                {
                    title: 'Test',
                    sub:
                        'De neusswab (antigeen sneltest) en/of vingerprik (antibody sneltest) zal worden afgenomen door medisch geschoold personeel',
                },
                {
                    title: 'Resultaat',
                    sub: 'U ontvangt na 15 minuten de testuitslag via de e-mail',
                },
            ],
        },
        flowEN: {
            type: Array,
            default: () => [
                {
                    title: 'Make an appointment ',
                    sub: 'Choose date, time & location',
                },
                {
                    title: 'Receive email confirmation',
                    sub: 'You will receive an email confirmation (check your spam if necessary)',
                },
                {
                    title: 'Go to the test location',
                    sub: 'Make sure to be there 5 minutes early and follow the instructions',
                    extra: "Don't forget to bring your ID",
                },
                {
                    title: 'Test',
                    sub: `The nasal swab (antigen rapid test) and/or blood test (antibody rapid test) is taken by
                medically trained personnel`,
                },
                {
                    title: 'Result',
                    sub: 'You will receive the test results after 15 minutes via e-mail',
                },
            ],
        },
    },

    computed: {
        language() {
            return this.$store.state.language
        },
    },
}
</script>

<style lang="scss" scoped>
section.howdoesitwork {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: $gutter * 2 0;
    text-align: center;
    counter-reset: section;

    @include desktop {
        padding: $gutter 0;
    }

    h3 {
        font-size: rem(24px);
        font-weight: $semi-bold;
        color: $color-burgundy;
        margin: 0 0 $gutter 0;
        padding: 0;
    }

    ul {
        max-width: 810px;
        width: 100%;
        margin: 0 auto;
        padding: 0 $spacer;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include tablet {
            flex-direction: column;
        }

        li {
            font-weight: $semi-bold;
            font-size: rem($base-font-size);
            color: $color-maroon;
            position: relative;

            &:before {
                counter-increment: section;
                content: counter(section) '.';
                color: $color-tangerine;
            }

            span {
                font-size: rem(14px);
                font-weight: $normal;
                color: $color-tangerine;
                display: block;
                margin-top: 10px;

                &.extra {
                    font-weight: $semi-bold;
                    color: $color-tangerine;
                }
            }

            &:last-of-type {
                padding-right: 0;
            }

            &:not(:last-of-type):after {
                content: '';
                background: url('../assets/icons/icon-arrow-right.svg');
                background-repeat: no-repeat;
                width: 16px;
                height: 14px;
                position: static;
                display: block;
                margin: $spacer + 10 auto;
                transform: rotate(90deg);
            }
        }
    }

    a.btn {
        margin-top: $gutter;
    }
}
</style>
