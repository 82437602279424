<template>
  <div>
    <div class="about">
      <h1 v-if="language === 'en'">{{ h1EN }}</h1>
      <h1 v-else>{{ h1 }}</h1>

      <div class="grid--location">
        <div>
          <p v-html="infoEN" v-if="language === 'en'" class="color-tangerine"></p>
          <p v-html="info" v-else class="color-tangerine"></p>
        </div>        
      </div>

      <div class="locations">
          <div class="location" v-for="location in locations" :key="location.city">
            <a :href="location.route" target="_blank">
              <img :src="location.img" :alt="location.address" class="thumb" />

              <div class="location--address">
                <div>
                  <img :src="location.icon" :alt="location.address" class="icon" />
                </div>

                <div>
                  <span class="color-tangerine">{{ location.city }}</span>
                  <span class="color-burgundy" v-html="location.address"></span>
                </div>
              </div>
            </a>
          </div>
      </div>
    </div>

    <Usp></Usp>
  </div>
</template>

<script>
import Usp from '@/components/Usp.vue'

export default {
  name: 'About',

  components: {
    Usp,
  },

  props: {
    h1: {
      type: String,
      default: 'Over ons',
    },
    h1EN: {
      type: String,
      default: 'About us',
    },
    locations: {
      type: Array,
      default: () => [
        {
          img: require('../assets/Rooswijck5-Amsterdam.jpeg'),
          city: 'Amsterdam',
          address: 'P3 Gelderlandplein, 1082 KZ Amsterdam',
          icon: require('../assets/icons/icon-map-pin.svg'),
          route: 'https://www.google.com/maps/dir//P3+Gelderlandplein,+1082+KZ+Amsterdam',
        },  
        // {
        //   img: require('../assets/danielgoedkoop.jpeg'),
        //   city: 'Amsterdam-zuid',
        //   address: 'Daniël Goedkoopstraat 23a, 1096 BD Amsterdam',
        //   icon: require('../assets/icons/icon-map-pin.svg'),
        //   route: 'https://www.google.com/maps/dir//Daniël+Goedkoopstraat+23a,+1096+BD+Amsterdam',
        // },               
        {
          img: require('../assets/depondnieuw-vennep.png'),
          city: 'Nieuw-Vennep',
          address: 'Pondweg 7, 2153 PK Nieuw-Vennep',
          icon: require('../assets/icons/icon-map-pin.svg'),
          route: 'https://www.google.com/maps/place/Pondweg+7+2153+PK+Nieuw-Vennep',
        }, 
        // {
        //   img: require('../assets/Hilton-Schiphol@2x.png'),
        //   city: 'Schiphol',
        //   address: 'Hilton hotel, Schiphol Boulevard 701, Schiphol',
        //   icon: require('../assets/icons/icon-map-pin.svg'),
        //   route: 'https://www.google.com/maps/dir/52.5018064,5.4685758/schiphol+hilton/@52.3957648,4.5626603,9z',
        // }, 
        // {
        //   img: require('../assets/Sheraton-Schiphol@2x.png'),
        //   city: 'Schiphol',
        //   address: 'Sheraton Hotel, Schiphol Boulevard 101, Schiphol',
        //   icon: require('../assets/icons/icon-map-pin.svg'),
        //   route: 'https://www.google.com/maps/place/Sheraton+Amsterdam+Airport+Hotel+and+Conference+Center',
        // },    
        {
          img: require('../assets/Drachten@2x.png'),
          city: 'Drachten',
          address: 'Bouriciuslaan 100, 9203 PK  Drachten',
          icon: require('../assets/icons/icon-map-pin.svg'),
          route: 'https://www.google.com/maps/place/Bouriciuslaan+100+9203+PK+Drachten',
        },                               
      ],
    },
    info: {
      type: String,
      default: ` 
        Na de eerste golf van het COVID-19 virus hebben wij (ondernemers uit regio Amsterdam) de krachten gebundeld om adequaat te reageren op alle uitdagingen die het virus met zich meebrengt. Onnodige quarantaine en ziekteverzuim brengen bedrijven in grote financiële problemen. Als ondernemers hebben we dit zelf ondervonden.<br><br> 
        Onze visie is dat de Nederlandse maatschappij veel baat kan hebben bij betrouwbare en snelle testen. Alleen zo kunnen we bedrijven, scholen en zorginstellingen ontlasten. Het is ons doel om zoveel mogelijk mensen te voorzien van een betrouwbare en snelle test, zodat we elkaar en onze maatschappij zo gezond mogelijk kunnen houden.<br><br> 
        Wij werken in goed overleg met de GGD en onze testen zijn goedgekeurd door het RIVM en OMT. De testen worden afgenomen door medisch geschoold personeel.
      `,
    },
    infoEN: {
      type: String,
      default: ` 
        After the first wave of the COVID-19 virus, we (entrepreneurs from Amsterdam) joined forces to respond adequately to all challenges posed by the virus. Unnecessary quarantine and absenteeism put companies in big financial problems. As entrepreneurs we have experienced this ourselves.<br><br>
        Our vision is that Dutch society can benefit greatly from reliable and rapid testing. This is the only way we can relieve companies, schools and care institutions. Our goal is to provide as many people as possible with a reliable and quick test, so that we can keep each other and our society as healthy as possible.<br><br>
        We work in close consultation with the GGD and our tests are approved by the RIVM and OMT. The tests are taken by medically trained personnel. 
      `,
    },
  },

  computed: {
    language() {
      return this.$store.state.language
    },
  },
}
</script>

<style lang="scss">
.locations {
  max-width: 820px;
  width: 100%;
  margin: $spacer auto 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $spacer;
  justify-content: center;
  align-items: center;
  text-align: left;

    @include mobile {
      display: block;

      .location {
        margin-bottom: $gutter;
      }
    }

        a {
      text-decoration: none;
    }


      img.thumb {
        max-width: 100%;
        // height: 180px;
        object-fit: cover;
        border-radius: 8px;
      }

      .location--address {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        margin-top: 10px;

        img.icon {
          width: 18px;
          height: 18px;
          background-size: cover;
        }
      }    
}

.about {
  text-align: center;
  margin: $gutter * 2 auto;
  padding: 0 $spacer;
  max-width: 1100px;
  width: 100%;
  padding: 0 $spacer;

  @include desktop {
    margin: $gutter auto;
  }

  .grid--location {
    max-width: 820px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $gutter * 2;
    margin: 0 auto;

    @include mobile {
      display: block;
    }
    .location {
      display: block;
      margin-bottom: $gutter;

      @include mobile {
        width: fit-content;
        margin: 0 auto $gutter;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  h1 {
    font-weight: $semi-bold;
    color: $color-burgundy;
    font-size: rem(24px);
    text-align: center;
    margin: 0 0 $gutter;
  }

  p {
    font-size: rem(14px);
    line-height: 1.5;
    margin-bottom: $spacer;
  }

  span {
    font-size: rem(14px);
    display: block;
  }
}
</style>
