<template>
    <aside :class="{ date: $route.name == 'Appointment' || ('Afspraak' && !checkout) }">
        <span class="title">{{ $route.name }}</span>

        <div class="info">
            <div><img :src="mapIcon" :alt="date.location" /></div>
            <div>
                <span>{{ date.location }}</span>
            </div>
        </div>

        <div class="info">
            <div><img :src="calendarIcon" :alt="date.selectedTime" /></div>
            <div>
                <span v-if="!formattedDate">{{ date.date.toLocaleDateString() }}, {{ date.selectedTime }}</span>
                <span v-else>{{ formattedDate }}, {{ date.selectedTime }}</span>
            </div>
        </div>

        <a class="btn" @click="emitDate()">{{ language == 'en' ? btnTextEN : btnText }}</a>
    </aside>
</template>

<script>
import { api } from '@/api'

export default {
    name: 'Aside',

    props: {
        title: {
            Type: String,
            default: 'Coronatest',
        },
        locationText: {
            Type: String,
            default: 'Op locatie',
        },
        locationTextEN: {
            Type: String,
            default: 'At location',
        },
        mapIcon: {
            Type: String,
            default: require('../assets/icons/icon-map-pin.svg'),
        },
        calendarIcon: {
            Type: String,
            default: require('../assets/icons/icon-calendar.svg'),
        },
        btnText: {
            Type: String,
            default: 'Volgende',
        },
        btnTextEN: {
            Type: String,
            default: 'Next',
        },
        apply: {
            type: String,
            default: 'indienen',
        },
        applyEN: {
            type: String,
            default: 'apply',
        },
        checkmark: {
            type: String,
            default: require('../assets/icons/icon-check.svg'),
        },
        checkout: Boolean,
        date: Object,
    },

    methods: {
        emitDate(prevent) {
            this.$emit('emitdate', this.date)
        },

        splitEmail(email, check) {
            if (!email.length) return
            const splitted = email.split('@')
            return splitted[1].includes(check)
        },
    },

    computed: {
        formattedDate() {
            return this.$store.state.date.date
        },

        getLocation() {
            return this.$store.state.location
        },

        language() {
            return this.$store.state.language
        },
    },
}
</script>

<style lang="scss" scoped>
aside {
    background: rgba(52, 193, 195, 0.02);
    width: 100%;
    height: fit-content;
    border: 1px solid $color-salmon;
    padding: $spacer - 4;
    grid-column: 3/4;
    border-radius: 6px;

    &.date {
        grid-column: 4/5;
    }

    @include mobile {
        grid-column: 1 !important;
    }

    .title {
        font-size: rem($base-font-size);
        font-weight: $semi-bold;
        color: $color-maroon;
        display: block;
    }

    .sub-title {
        @extend .title;
        font-weight: $normal;
    }

    .info {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 10px;
        margin-top: $spacer - 4;

        &:last-of-type {
            margin-bottom: $spacer;
        }

        span {
            font-size: rem(14px);
            font-weight: $semi-bold;
            color: $color-maroon;
        }
    }

    a.btn {
        padding: 10px $gutter 10px $spacer;
        font-size: rem(14px);
        margin-bottom: 10px;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

input[type='text'] {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    padding: 0 10px;
    color: $color-maroon;
    outline: 0;
    border: 1px solid rgba(52, 193, 195, 0.4);
    border-radius: 6px;
    box-shadow: 0px 4px 5px rgba(57, 9, 35, 0.05);
    transition: border-color 0.2s;

    &:focus {
        border-color: rgba(52, 193, 195, 0.85);
        transition: border-color 0.2s;
    }

    &.error {
        border-color: $color-error;
        transition: border-color 0.2s;
    }
}
</style>
