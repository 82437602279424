<template>
    <div id="app">
        <transition name="fade"><Loader v-if="loading"/></transition>
        <Menu v-if="!loading"></Menu>
        <router-view v-if="!loading" />
        <Footer v-if="!loading"></Footer>
    </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'

export default {
    components: {
        Menu,
        Footer,
        Loader,
    },

    watch: {
        $route() {
            if (!this.loading) {
                document.querySelector('.mobile-menu').classList.remove('show')
                document.querySelector('nav').classList.remove('scrolling')
            }
        },
    },

    computed: {
        language() {
            return this.$store.state.language
        },

        loading() {
            return this.$store.state.loading
        },
    },
}
</script>

<style lang="scss">
* {
    box-sizing: border-box;
}

#app,
body {
    background: $color-white;
    padding: 0;
    margin: 0;
    font-family: $Signika;
    font-size: rem($base-font-size);
    font-weight: $normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-maroon;
}

body {
    padding-top: 90px;

    &.no-padding {
        padding-top: 0;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
