import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'
import VueGtag from 'vue-gtag'
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.config.productionTip = false

Vue.use(VCalendar, {
    componentPrefix: 'vc',
})

Vue.use(
    VueGtag,
    {
        config: {
            id: 'UA-182187208-1',
        },
        includes: [{ id: 'AW-465863629' }],
    },
    router
)

Vue.use(VueSmoothScroll)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
