<template>
    <section>
        <h3 v-if="language === 'en'">{{ h3EN }}</h3>
        <h3 v-else>{{ h3 }}</h3>

        <ul v-if="language === 'en'" :class="{ vertical: vertical }">
            <li v-for="item in flowEN" :key="item">{{ item }}</li>
        </ul>

        <ul v-else :class="{ vertical: vertical }">
            <li v-for="item in flow" :key="item">{{ item }}</li>
        </ul>

        <AppointmentButton v-if="!nobtn"></AppointmentButton>

        <div class="embed-container" v-if="showVideo">
            <iframe src="https://www.youtube.com/embed/hqXQ-daKPlc" frameborder="0" allowfullscreen></iframe>
        </div>
    </section>
</template>

<script>
import AppointmentButton from '@/components/AppointmentBtn.vue'

export default {
    name: 'HowDoesItWork',

    components: {
        AppointmentButton,
    },

    props: {
        h3: {
            type: String,
            default: 'Hoe werkt het?',
        },
        h3EN: {
            type: String,
            default: 'How does it work?',
        },
        nobtn: {
            type: Boolean,
            default: false,
        },
        flow: {
            type: Array,
            default: () => [
                'Maak een afspraak',
                'Ontvang de email bevestiging',
                'Kom naar de gekozen snelteststraat',
                'Test',
                'Resultaat',
            ],
        },
        flowEN: {
            type: Array,
            default: () => [
                'Make an appointment',
                'Receive email confirmation ',
                'Go to the test location',
                'Take the test',
                'Result',
            ],
        },
        showVideo: false,
        vertical: false,
    },

    computed: {
        language() {
            return this.$store.state.language
        },
    },
}
</script>

<style lang="scss" scoped>
section {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: $gutter * 2 0;
    text-align: center;

    @include desktop {
        padding: $gutter $spacer;
    }

    .embed-container {
        position: relative;
        height: 250px;
        overflow: hidden;
        max-width: 500px;
        width: 100%;
        margin: $gutter auto 0;

        iframe,
        object,
        embed {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    h3 {
        font-size: rem(24px);
        font-weight: $bold;
        color: $color-maroon;
        margin: 0 0 $gutter 0;
        padding: 0;
    }

    ul {
        // max-width: 810px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;

        @include tablet {
            flex-direction: column;
        }

        li {
            font-weight: $normal;
            color: $color-tangerine;
            padding-right: $spacer + 10;
            position: relative;

            @include tablet {
                padding-right: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }

            &:not(:last-of-type):after {
                content: '';
                background: url('../assets/icons/icon-arrow-right-green.svg');
                background-repeat: no-repeat;
                width: 16px;
                height: 14px;
                position: absolute;
                right: 0;
                top: 3px;

                @include tablet {
                    position: static;
                    display: block;
                    margin: 12px auto;
                    transform: rotate(90deg);
                }
            }
        }

        &.vertical {
            display: block;

            li {
                padding-right: 0;
            }

            li:not(:last-of-type):after {
                position: static;
                display: block;
                margin: 12px auto;
                transform: rotate(90deg);
            }
        }
    }
}
</style>
