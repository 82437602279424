<template>
    <header>
        <section>
            <h1 v-if="language === 'en'">{{ h1EN }}</h1>
            <h1 v-if="language == 'nl'">{{ h1 }}</h1>

            <h2>{{ language == 'nl' ? 'Bij' : 'At' }} {{ $store.state.companyData.bedrijfsnaam }}</h2>

            <div class="btn-wrapper">
                <div>
                    <AppointmentButton></AppointmentButton>
                </div>
            </div>

            <ul v-if="language === 'en'">
                <li v-for="item in uspsEN" :key="item">
                    <span v-html="item"></span>
                </li>
            </ul>
            <ul v-else>
                <li v-for="item in usps" :key="item">
                    <span v-html="item"></span>
                </li>
            </ul>

            <p class="helper">
                {{ language == 'nl' ? 'Vragen? Neem contact op met ' : 'Questions? Please contact us at ' }}
                <a :href="`mailto:${$store.state.companyData.emailAfspraken}`">{{
                    $store.state.companyData.emailAfspraken
                }}</a>
                / <a :href="`tel:${$store.state.companyData.telnr}`">{{ $store.state.companyData.telnr }}</a>
            </p>
        </section>
    </header>
</template>

<script>
import AppointmentButton from '@/components/AppointmentBtn.vue'

export default {
    name: 'Header',

    components: {
        AppointmentButton,
    },

    props: {
        h1: {
            type: String,
            default: `Veilig aan het werk`,
        },
        h1EN: {
            type: String,
            default: 'Work safely',
        },
    },

    data() {
        return {
            usps: ['Afgenomen door erkende zorgverleners', 'Binnen 15 minuten resultaat'],

            uspsEN: ['Taken by licensed health care providers', 'Results within 15 minutes'],
        }
    },
    computed: {
        language() {
            return this.$store.state.language
        },

        location() {
            return this.$store.state.location
        },
    },
}
</script>

<style lang="scss">
header {
    background: url('../assets/header-bg@2x.jpeg') center bottom no-repeat;
    background-size: cover;
    width: 100%;
    height: 950px;
    position: relative;

    @include mobile {
        height: 110vh;
    }

    @media screen and (max-height: 667px) {
        height: 120vh;
    }

    section {
        max-width: 900px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        // @include mobile {
        //   top: calc(50% + 60px);
        // }

        .btn-wrapper {
            max-width: 640px;
            width: 100%;
            margin: 0 auto;
            display: grid;
            grid-gap: 0 $gutter;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            justify-items: center;

            @include mobile {
                display: block;
            }

            // & > div:last-of-type {
            //   grid-column: span 2;
            // }

            span.maps {
                color: $color-maroon;
                font-size: rem(16px);
                font-weight: $light;
                margin-top: 10px;
                display: block;

                img {
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }

        .inner--grid {
            max-width: 300px;
            width: 100%;
            margin: $gutter * 2 auto 0;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: $spacer;
            text-align: left;

            @include tablet {
                width: fit-content;
            }

            img {
                max-width: 110px;
            }

            span.big {
                font-size: rem(16px);
                color: $color-maroon;
                display: block;
                margin-top: $spacer + 10;
            }

            a.big-info {
                font-size: rem(12px);
                color: $color-maroon;
                text-decoration: none;
            }

            .title {
                color: $color-burgundy;
                display: block;
            }
        }

        h1 {
            font-weight: $semi-bold;
            color: $color-maroon;
            font-size: rem(48px);
            margin: 0 0 $spacer - 10;

            @include mobile-s {
                font-size: rem(28px);
                padding: 0 $spacer;
            }
        }

        h2 {
            font-weight: $normal;
            color: $color-tangerine;
            font-size: rem(18px);
            margin: 0;
            display: block !important;
        }

        .btn {
            width: auto;
            margin-top: $gutter;

            &:last-of-type {
                margin-right: 0;
            }

            @include mobile {
                margin-right: 0;
                margin-top: $spacer;

                &:last-of-type {
                    margin-top: 10px;
                }
            }
        }

        ul {
            width: fit-content;
            list-style: none;
            padding: 0;
            margin: $gutter * 2 auto 0;
            text-align: left;

            @include mobile {
                padding: 0 $spacer;
                margin: $gutter auto 0;
            }

            li {
                position: relative;
                display: block;
                margin-right: $spacer;
                margin-bottom: $spacer;

                @include mobile {
                    width: 100%;
                    margin-right: 0;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    background: url('../assets/icons/icon-check.svg');
                    background-size: cover;
                    width: 18px;
                    height: 16px;
                    margin-right: $spacer;
                    vertical-align: middle;

                    @include mobile {
                        float: left;
                        margin-left: 0;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }

            span {
                display: inline-block;
                font-style: normal;
                font-size: rem(18px);
                font-weight: $normal;
                color: $color-tangerine;

                @include mobile {
                    padding-left: 5px;
                    max-width: 80%;
                }

                a {
                    color: $color-burgundy;
                }
            }
        }

        p.helper {
            color: $color-tangerine;
            font-size: rem(16px);
            margin-top: $gutter;

            a {
                color: $color-tangerine;
            }
        }
    }
}
</style>
