import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    date: {
        date: '',
        time: '',
        location: '',
    },
    userData: {
        name: '',
        bday: '',
        email: '',
        mobile: '',
    },
    companyData: {
        bedrijfsnaam: '',
        emailAfspraken: '',
        emailHR: '',
        telnr: '',
        id: null,
    },
    locations: [],
    language: 'nl',
    location: 0,
    showMobileMenu: false,
    showModal: true,
    loading: true,
}

export const mutations = {
    SET_LOADING(state, bool) {
        state.loading = bool
    },

    SET_DATETIME(state, obj) {
        state.date = obj
    },

    SET_USERDATA(state, obj) {
        state.userData = obj
    },

    SET_EMAIL(state, val) {
        state.userData.email = val
    },

    SET_COMPANYDATA(state, obj) {
        state.companyData = obj
    },

    SET_LOCATIONS(state, arr) {
        state.locations = arr
    },

    SET_MOBILEMENU(state, bool) {
        state.showMobileMenu = bool
    },

    SET_COUPONCODE(state, val) {
        state.coupon.code = val
    },

    SET_LANGUAGE(state, val) {
        state.language = val
    },

    SET_LOCATION(state, val) {
        state.location = val
    },

    SET_LOCATIONTEXT(state, val) {
        state.date.location = val
    },

    SET_MODAL(state, bool) {
        state.showModal = bool
    },
}

const store = new Vuex.Store({
    state,
    mutations,
})

export default store
