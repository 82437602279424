<template>
    <a class="btn" @click="emitLocation(0)">{{ language == 'en' ? buttonTextEN : buttonText }}</a>
</template>

<script>
export default {
    name: 'AppointmentButton',

    props: {
        buttonText: {
            type: String,
            default: 'Maak een afspraak',
        },
        buttonTextEN: {
            type: String,
            default: 'Make an appointment',
        },
    },

    methods: {
        emitLocation(loc, routeEN) {
            let finalRoute = this.language == 'en' ? 'Appointment' : 'Afspraak'
            this.$store.commit('SET_LOCATION', loc)
            this.$router.push({ name: finalRoute })
        },
    },

    computed: {
        language() {
            return this.$store.state.language
        },
    },
}
</script>

<style lang="scss">
.btn {
    background-image: linear-gradient(45deg, #2cbc63 0%, #4de28e 100%);
    display: inline-block;
    height: auto;
    padding: 14px $gutter 14px $spacer;
    border: 0;
    border-radius: 6px;
    text-decoration: none;
    text-align: left;
    color: $color-white;
    font-size: rem(16px);
    font-weight: $bold;
    position: relative;
    cursor: pointer;
    z-index: 1;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(45deg, #279b54 0%, #40c579 100%);
        z-index: -1;
        transition: opacity 0.3s;
        opacity: 0;
        border-radius: 6px;
    }

    &:after {
        content: '';
        background: url('../assets/icons/icon-arrow-right.svg');
        background-repeat: no-repeat;
        width: 16px;
        height: 14px;
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover:before {
        opacity: 1;
        transition: opacity 0.3s;
    }
}
</style>
