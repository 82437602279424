<template>
    <footer>
        <div class="top-footer">
            <div class="center-content">
                <div class="flex--container">
                    <div>
                        <span class="title color-white">{{
                            language == 'en'
                                ? 'Do you have questions about you commercial test?'
                                : 'Heeft u vragen over een commerciele test?'
                        }}</span>

                        <ul>
                            <li>
                                <img src="@/assets/icons/icon-mail.svg" alt="" />
                                <a href="mailto:info@premiumtesting.nl">info@premiumtesting.nl</a>
                            </li>
                            <li>
                                <img src="@/assets/icons/icon-whatsapp.svg" alt="" />
                                <a href="https://wa.me/31629511422">06 295 114 22</a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div>
                        <ul>
                            <li><router-link to="/werkwijze">Werkwijze</router-link></li>
                            <li><router-link to="/faq">Veel gestelde vragen</router-link></li>
                        </ul>
                    </div> -->

                    <div>
                        <span class="title color-white">{{ language == 'en' ? locationTitleEN : locationTitle }}</span>
                        <ul>
                            <li class="location--grid" v-for="location in locations" :key="location.id">
                                <div><img src="@/assets/icons/icon-map-pin.svg" :alt="location.locatienaam" /></div>

                                <div>
                                    <a
                                        :href="
                                            `https://www.google.com/maps/place/${location.adres.split(' ').join('+')}`
                                        "
                                        target="_blank"
                                    >
                                        <span class="location-title">{{ location.locatienaam }}</span>
                                        <cite class="color-burgundy">{{ location.adres }}</cite>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <span class="color-tangerine">{{ copyright }}</span>

            <ul>
                <li>
                    <a class="subfooter" href="algemene-voorwaarden.pdf" target="_blank">{{
                        language === 'nl' ? 'Algemene voorwaarden' : 'Terms and Conditions'
                    }}</a>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
import store from '../store/index.js'

export default {
    name: 'Footer',

    props: {
        copyright: {
            type: String,
            default: '© 2022 premiumtesting.nl',
        },
        locationTitle: {
            type: String,
            default: 'Testlocaties',
        },
        locationTitleEN: {
            type: String,
            default: 'Testlocations',
        },
        directContact: {
            type: String,
            default: 'Direct contact',
        },
        directContactEN: {
            type: String,
            default: 'Contact us',
        },
    },

    data() {
        return {
            footerItems: [
                {
                    name: 'Disclaimer',
                    route: '/disclaimer',
                },
            ],
            footerItemsEN: [
                {
                    name: 'Disclaimer',
                    route: '/disclaimer',
                },
            ],
        }
    },

    computed: {
        language() {
            return this.$store.state.language
        },

        locations() {
            return this.$store.state.locations
        },
    },
}
</script>

<style lang="scss">
footer {
    width: 100%;
    min-height: 90px;

    @include mobile {
        height: auto;
    }

    .top-footer {
        background: $color-maroon;
        padding: 30px 0;

        .center-content {
            max-width: 1100px;
            width: 100%;
            margin: 0 auto;
            padding: 0 $spacer;
        }

        .flex--container {
            display: flex;
            justify-content: space-between;

            @include mobile {
                display: block;

                & > div {
                    margin-bottom: $gutter;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            span.title {
                font-size: rem(16px);
                font-weight: $bold;
                margin-bottom: 10px;
                display: block;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: block;
                    margin-bottom: 8px;

                    &.location--grid {
                        display: grid;
                        grid-template-columns: auto 1fr;
                    }

                    img {
                        width: 18px;
                        margin-right: 10px;
                        object-fit: cover;
                        vertical-align: middle;
                    }

                    a {
                        text-decoration: none;
                        font-size: rem(14px);
                        color: $color-white;
                    }

                    .location-title {
                        font-size: rem(16px);
                        font-weight: $bold;
                        color: $color-white;
                        display: inline-block;
                    }

                    cite {
                        margin: 0;
                        display: block;
                        opacity: 1;
                        padding: 0;
                        text-align: left;
                        font-size: rem(14px);
                    }
                }
            }
        }
    }

    .container {
        max-width: 1100px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacer $spacer;
        margin-bottom: $spacer;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include mobile {
            display: block;
            text-align: center;
        }

        span {
            font-size: rem(12px);

            small {
                display: block;
            }

            @include mobile {
                display: block;
                margin-bottom: $spacer;
            }

            a {
                text-decoration: none;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: space-between;

            @include mobile {
                display: block;
            }

            li {
                margin-right: $spacer;

                @include mobile {
                    margin-right: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                a {
                    color: $color-echoblue;
                    font-size: rem(12px);
                    text-decoration: none;

                    &.subfooter {
                        color: $color-tangerine;
                    }
                }
            }
        }
    }
}
</style>
