import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Appointment from '../views/Appointment.vue'
import Gegevens from '../views/Gegevens.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import HowDoesItWork from '../views/HowDoesItWork.vue'
import Faq from '../views/FAQ.vue'
import About from '../views/About.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

import { api } from '@/api'

const setCompanyStates = obj => {
    store.commit('SET_COMPANYDATA', obj.company)
    store.commit('SET_LOCATIONS', obj.locations)
}

const initialCheckCompany = async val => {
    try {
        const response = await api.get('/checkCompany', {
            headers: {
                company: val,
            },
        })
        const result = await response.data

        setCompanyStates(result)
        localStorage.setItem('company', result.company.bedrijfsnaam)

        setTimeout(() => {
            store.commit('SET_LOADING', false)
        }, 1500)
    } catch (error) {
        console.log(error)
    }
}

const routes = [
    {
        path: '/:company',
        name: 'Home',
        component: Home,
        async beforeEnter(to, from, next) {
            await initialCheckCompany(to.params.company)
            document.querySelector('body').classList.add('no-padding')
            next()
        },
    },

    {
        path: '/:company/afspraak/datum',
        name: 'Afspraak',
        component: Appointment,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            next()
        },
    },
    {
        path: '/:company/afspraak/gegevens',
        name: 'Gegevens',
        component: Gegevens,
        beforeEnter(to, from, next) {
            if (!store.state.date.date && !store.state.date.location && !store.state.date.time) {
                next({ path: `/${to.params.company}` })
            } else {
                document.querySelector('body').classList.remove('no-padding')
                next()
            }
        },
    },
    {
        path: '/:company/over-ons',
        name: 'About',
        component: About,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            next()
        },
    },
    // {
    //     path: '/:company/hoe-werkt-het',
    //     name: 'Werkwijze',
    //     component: HowDoesItWork,
    //     beforeEnter(to, from, next) {
    //         document.querySelector('body').classList.remove('no-padding')
    //         next()
    //     },
    // },
    {
        path: '/:company/faq',
        name: 'Faq',
        component: Faq,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            next()
        },
    },
    {
        path: '/:company/betaald',
        name: 'Betaald',
        component: PaymentSuccess,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            store.commit('SET_MODAL', false)
            next()
        },
    },

    {
        path: '/appointment/date',
        name: 'Appointment',
        component: Appointment,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            next()
        },
    },
    {
        path: '/appointment/info',
        name: 'UserInfo',
        component: Gegevens,
        beforeEnter(to, from, next) {
            if (!store.state.date.date && !store.state.date.location && !store.state.date.time) {
                next({ path: `/${to.params.company}` })
            } else {
                document.querySelector('body').classList.remove('no-padding')
                next()
            }
        },
    },
    {
        path: '/about-us',
        name: 'About',
        component: About,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            next()
        },
    },
    // {
    //     path: '/how-does-it-work',
    //     name: 'HowDoesItWork',
    //     component: HowDoesItWork,
    //     beforeEnter(to, from, next) {
    //         document.querySelector('body').classList.remove('no-padding')
    //         next()
    //     },
    // },
    {
        path: '/faq',
        name: 'Faq',
        component: Faq,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            next()
        },
    },
    {
        path: '/paymentSuccess',
        name: 'PaymentSuccess',
        component: PaymentSuccess,
        beforeEnter(to, from, next) {
            document.querySelector('body').classList.remove('no-padding')
            store.commit('SET_MODAL', false)
            next()
        },
    },

    // {
    //     path: '*', // 404
    //     beforeEnter(to, from, next) {
    //         location.href = '/'
    //     },
    // },
]

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes,
})

router.beforeEach(async (to, from, next) => {
    console.log(to.name)
    console.log(store.state.locations.length)

    if (to.path == '/') return window.location.replace('https://premiumtesting.nl')

    // if (!localStorage.getItem('company')) return window.location.replace('https://premiumtesting.nl')

    if (localStorage.getItem('company')) await initialCheckCompany(localStorage.getItem('company'))

    if (document.querySelector('.mobile-menu.show')) {
        document.querySelector('.mobile-menu').classList.remove('show')
    }

    store.commit('SET_MOBILEMENU', false)
    next()
})

export default router
