<template>
    <div class="home">
        <Header></Header>

        <section class="cards">
            <div class="card__wrapper">
                <Card>
                    <template v-slot:header>
                        Veilig
                    </template>

                    <template v-slot:content>
                        Medewerkers hebben het recht op een gezonde werkomgeving met veilige ruimte voor contact.
                    </template>
                </Card>

                <Card>
                    <template v-slot:header>
                        Verantwoord
                    </template>

                    <template v-slot:content>
                        Onder directe supervisie van (huis)artsen, in samenwerking met erkende laboratoria en de GGD.
                    </template>
                </Card>

                <Card>
                    <template v-slot:header>
                        Vlot
                    </template>

                    <template v-slot:content>
                        Je kunt rekenen op een verantwoorde en snelle testaanpak voor preventief testen.
                    </template>
                </Card>
            </div>
        </section>

        <FaqAccordion></FaqAccordion>
    </div>
</template>

<script>
import Card from '@/components/Card.vue'
import Header from '@/components/Header.vue'
import FaqAccordion from '@/components/FaqAccordion.vue'
import store from '../store/index.js'

export default {
    name: 'Home',

    components: {
        Header,
        Card,
        FaqAccordion,
    },

    data() {
        return {}
    },
}
</script>

<style lang="scss">
.col-2 {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $gutter;
}

section.cards {
    background: $color-white;
    width: 100%;
    margin: 0 auto;
    padding: 80px $spacer;

    .card__wrapper {
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: $gutter;
    }
}
</style>
