<template>
    <nav :class="[$route.name, isScrolling ? 'scrolling' : '']">
        <div class="container">
            <div class="logo">
                <router-link :to="{ name: 'Home' }"><img :src="logo"/></router-link>
            </div>

            <ul>
                <li v-for="item in language == 'en' ? menuItemsEN : menuItems" :key="item.name">
                    <router-link :to="{ name: item.compName }">{{ item.name }}</router-link>
                </li>
                <!-- <li>
                    <span :class="language === 'nl' ? 'active' : ''" @click="changeLanguage('nl')">NL</span>
                    <span :class="language === 'en' ? 'active' : ''" @click="changeLanguage('en')">EN</span>
                </li> -->
            </ul>

            <div class="hamburger" @click="mobileMenu((mobileToggle = !mobileToggle))">
                <span>-</span>
                <span>-</span>
                <span>-</span>
            </div>
        </div>

        <div class="mobile-menu" :class="{ show: showMobile }">
            <ul class="mobile-list">
                <li v-for="item in language == 'en' ? menuItemsEN : menuItems" :key="item.name">
                    <router-link :to="{ name: item.compName }">{{ item.name }}</router-link>
                </li>
            </ul>
            <!-- 
            <div class="language mobile">
                <span :class="language === 'nl' ? 'active' : ''" @click="changeLanguage('nl')">NL</span>
                <span :class="language === 'en' ? 'active' : ''" @click="changeLanguage('en')">EN</span>
            </div> -->
        </div>
    </nav>
</template>

<script>
import { api } from '@/api'

export default {
    name: 'Menu',

    props: {
        logo: {
            type: String,
            default: require('../assets/premiumtesting-stacked.svg'),
        },
    },

    data() {
        return {
            isScrolling: false,
            mobileToggle: false,
            menuItems: [
                {
                    name: 'Home',
                    compName: 'Home',
                },
                // {
                //     name: 'Werkwijze',
                //     compName: 'Werkwijze',
                // },
                {
                    name: 'Veelgestelde vragen',
                    compName: 'Faq',
                },
                // {
                //     name: 'Contact',
                //     compName: 'Home',
                // },
            ],
            menuItemsEN: [
                {
                    name: 'Home',
                    compName: 'Home',
                },
                // {
                //     name: 'How it works',
                //     compName: 'HowDoesItWork',
                // },
                {
                    name: 'Faq',
                    compName: 'Faq',
                },
                // {
                //     name: 'Contact',
                //     compName: 'Home',
                // },
            ],
        }
    },

    methods: {
        mobileMenu(e) {
            this.$store.commit('SET_MOBILEMENU', this.mobileToggle)
        },

        changeLanguage(lang) {
            this.$store.commit('SET_LANGUAGE', lang)
            this.$store.commit('SET_MOBILEMENU', false)
        },

        onScroll(e) {
            const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop

            if (currentScrollPos < 80) {
                this.isScrolling = false
                return
            }

            this.isScrolling = true
        },
    },

    async mounted() {
        window.addEventListener('scroll', this.onScroll)
    },

    computed: {
        showMobile() {
            this.mobileToggle = this.$store.state.showMobileMenu
            return this.$store.state.showMobileMenu
        },

        language() {
            return this.$store.state.language
        },
    },
}
</script>

<style lang="scss" scoped>
nav {
    background-color: $color-header;
    background-size: cover;
    width: 100%;
    height: 90px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: background 0.2s, background-color 0.2s;

    @include large {
        background: $color-white !important;
        background-color: $color-white !important;
        transition: background 0.2s, background-color 0.2s;
    }

    &.scrolling {
        background: $color-white !important;
        background-color: $color-white !important;
        transition: background 0.2s, background-color 0.2s;
    }

    &.Home,
    &.HomeNV {
        background: transparent;
        background-color: transparent;
        transition: background 0.2s, background-color 0.2s;
    }

    .language {
        position: relative;

        @include large {
            position: static;
            margin-top: $spacer;
        }

        &.desktop {
            display: block;

            @include large {
                display: none;
            }
        }

        &.mobile {
            display: none;

            @include large {
                display: block;
            }
        }

        span {
            display: inline-block;
            margin-right: $spacer;
            font-weight: $semi-bold;
            cursor: pointer;
            transition: color 0.2s;

            &.active {
                color: $color-burgundy;
                transition: color 0.2s;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .container {
        max-width: 1400px;
        width: 100%;
        height: 100%;
        padding: $spacer;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1400px) {
            justify-content: start;
        }

        @include large {
            justify-content: space-between;
        }
    }

    .mobile-menu {
        background: $color-white;
        width: 100%;
        padding: $spacer;
        opacity: 0;
        transition: opacity 0.2s;

        &.show {
            background: $color-white;
            opacity: 1;
            transition-delay: 0.2s;
            transition: opacity 0.2s;

            ul.mobile-list {
                display: block;

                li:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .logo {
        max-width: 175px;
        width: 100%;

        img {
            max-width: 100%;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include large {
            display: none;
        }

        li {
            margin-right: $spacer + 10px;

            &:last-of-type {
                margin-right: 0;
            }

            a {
                font-weight: $semi-bold;
                font-size: rem(18px);
                color: $color-maroon;
                text-decoration: none;
                transition: color 0.2s;

                &:hover {
                    color: $color-burgundy;
                    transition: color 0.2s;
                }

                &.is-active {
                    color: $color-burgundy;
                }
            }
        }
    }

    .hamburger {
        width: 24px;
        height: 16px;
        cursor: pointer;
        display: none;

        @include large {
            display: block;
        }

        span {
            background: $color-tangerine;
            width: 100%;
            height: 2px;
            margin-bottom: 6px;
            font-size: 0;
            display: block;

            &:last-of-type {
                width: 80%;
                margin-bottom: 0;
                transition: width 0.2s;
            }
        }

        &:hover span:last-of-type {
            width: 100%;
            transition: width 0.2s;
        }
    }
}
</style>
