<template>
  <div class="usps">
	  <div class="container">
		  <ul v-if="language == 'en'">
			  <li v-for="usp in uspsEN" :key="usp">
				  <img :src="icon">
				  <span>{{ usp }}</span>
			  </li>
		  </ul>

		  <ul v-else>
			  <li v-for="usp in usps" :key="usp">
				  <img :src="icon">
				  <span>{{ usp }}</span>
			  </li>
		  </ul>
	  </div>
  </div>
</template>

<script>
export default {
	name: 'usp',

	props: {
		icon: {
			type: String,
			default: require('../assets/icons/icon-check.svg')
		},
		usps: {
			type: Array,
			default: () => [
				"Binnen 15 minuten resultaat",
				"Meldingsplicht aan GGD",
				"Testen goedgekeurd door RIVM en OMT"
			]
		},
		uspsEN: {
			type: Array,
			default: () => [
				"result within 15 minutes",
				"Collaboration with GGD",
				"Tests approved by RIVM and OMT"
			]
		}
	},


  computed: {
    language() {
      return this.$store.state.language
    }
  }
}
</script>

<style lang="scss" scoped>
	.usps {
		width: 100%;
		background: $color-usp;
		padding: $gutter $spacer;

		@include mobile {
			padding: $spacer;
		}

		.container {
			max-width: 1100px;
			width: 100%;
			height: 100%;
			margin: 0 auto;

			ul {
				width: fit-content;
				list-style: none;
				margin: 0 auto;
				padding: 0;
				display: block;

				li {
					margin-right: $spacer;
					display: inline-block;

					@include mobile {
						display: block;
						margin-bottom: 10px;
					}

					&:last-of-type {
						margin-right: 0;
					}

					span {
						font-weight: $light;
					}

					img {
						margin-right: 10px;
						vertical-align: middle;
					}
				}
			}
		}
	}
</style>>