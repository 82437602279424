<template>
    <div class="paymentsuccess">
        <h1>{{ language == 'en' ? h1EN : h1 }}</h1>

        <div class="column-2">
            <div>
                <h2>{{ language == 'en' ? h2EN : h2 }}</h2>
                <p v-if="$store.state.locations[0].bedanktpagina" v-html="$store.state.locations[0].bedanktpagina"></p>
                <p v-html="info" v-else></p>
            </div>

            <div>
                <div class="aside">
                    <div class="info">
                        <div><img :src="mapIcon" /></div>
                        <div>
                            <span>{{ $store.state.date.location }}</span>
                        </div>
                    </div>

                    <div class="info">
                        <div><img :src="calendarIcon" /></div>
                        <div>
                            <!-- <span v-if="!formattedDate"
                                >{{ $store.state.date.toLocaleDateString() }}, {{ $store.state.date.time }}</span
                            > -->
                            <span>{{ $store.state.date.date }}, {{ $store.state.date.selectedTime }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '@/api'

export default {
    name: 'PaymentSuccess',

    props: {
        h1: {
            type: String,
            default: 'Bedankt!',
        },
        h1EN: {
            type: String,
            default: 'Thank you!',
        },
        h2: {
            type: String,
            default:
                'Gelukt! Je hebt een afspraak gemaakt voor een coronatest. De bevestiging met alle details krijg je binnen enkele minuten in je mailbox of per SMS (als je geen mailadres hebt opgegeven).',
        },
        h2EN: {
            type: String,
            default: 'Thank you for your trust! See you at our test street.',
        },
        info: {
            type: String,
            default: `Bevestiging niet ontvangen? Check dan of het bericht in je spam terecht is gekomen.<br> 
Geen SMS ontvangen? Neem dan contact met ons op via <strong>info@premiumtesting.nl</strong> of <strong>06 295 11 422</strong>.
`,
        },
        mapIcon: {
            Type: String,
            default: require('../assets/icons/icon-map-pin.svg'),
        },
        calendarIcon: {
            Type: String,
            default: require('../assets/icons/icon-calendar.svg'),
        },
    },

    data() {
        return {
            locationId: this.getLocation(),
            language: this.getLanguage(),
            orderData: {},
        }
    },

    methods: {},
}
</script>

<style lang="scss">
.paymentsuccess {
    text-align: center;
    margin: $gutter auto;
    padding: 0 $spacer;

    .column-2 {
        display: inline-flex;
        flex-direction: row;
        gap: 40px;
        margin: 0 auto;
    }

    h1 {
        font-weight: $semi-bold;
        color: $color-burgundy;
        font-size: rem(24px);
        margin: 0 0 $gutter;
    }

    h2 {
        max-width: 650px;
        width: 100%;
        font-weight: $normal;
        color: $color-tangerine;
        font-size: rem($base-font-size);
        margin: 0 auto $spacer - 4;
        text-align: left;
    }

    p {
        max-width: 550px;
        width: 100%;
        margin: 0 auto $spacer - 4;
        margin-left: 0;
        color: $color-tangerine;
        font-size: rem(14px);
        text-align: left;
    }

    span {
        color: $color-maroon;
        font-weight: $semi-bold;
        display: block;
        text-align: left;
    }

    img {
        max-width: 460px;
        width: 100%;
        border-radius: 6px;
        margin-top: $gutter + $spacer;
    }

    cite {
        margin-top: 10px;
        font-size: rem(12px);
    }

    .aside {
        background: rgba(52, 193, 195, 0.02);
        width: 100%;
        height: fit-content;
        border: 1px solid $color-salmon;
        padding: $spacer - 4;
        grid-column: 3/4;
        border-radius: 6px;

        &.date {
            grid-column: 4/5;
        }

        @include mobile {
            grid-column: 1 !important;
        }

        .title {
            font-size: rem($base-font-size);
            font-weight: $semi-bold;
            color: $color-maroon;
            display: block;
        }

        .sub-title {
            @extend .title;
            font-weight: $normal;
        }

        .info {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: 10px;
            margin-top: $spacer - 4;

            img {
                margin-top: 0 !important;
                margin-right: 8px;
            }

            &:last-of-type {
                margin-bottom: $spacer;
            }

            span {
                font-size: rem(14px);
                font-weight: $semi-bold;
                color: $color-maroon;
            }
        }

        a.btn {
            padding: 10px $gutter 10px $spacer;
            font-size: rem(14px);
            margin-bottom: 10px;

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}
</style>
