<template>
    <div class="faq-accordion">
        <span class="title">{{ language == 'en' ? faqTitleEN : faqTitle }}</span>

        <div class="accordion" v-if="language == 'en'">
            <div
                :class="[faq.active ? 'active' : '', 'inner-wrapper']"
                v-for="(faq, index) in faqsEN"
                :key="faq.question"
                @click="showFaq(index)"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 10"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    class="arrow"
                >
                    <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" />
                </svg>
                <span class="question">{{ faq.question }}</span>
                <transition name="fade" mode="in-out">
                    <p class="answer" v-if="faq.active">{{ faq.answer }}</p>
                </transition>
            </div>
        </div>

        <div class="accordion" v-else>
            <div
                :class="[faq.active ? 'active' : '', 'inner-wrapper']"
                v-for="(faq, index) in faqs"
                :key="faq.question"
                @click="showFaq(index)"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 10"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    class="arrow"
                >
                    <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" />
                </svg>
                <span class="question">{{ faq.question }}</span>
                <transition name="fade" mode="out-in">
                    <p class="answer" v-if="faq.active" v-html="faq.answer"></p>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'faqAccordion',

    props: {
        faqTitle: {
            type: String,
            default: 'Veelgestelde vragen',
        },
        faqTitleEN: {
            type: String,
            default: 'Frequently asked questions',
        },
    },

    data() {
        return {
            faqs: [
                {
                    question: 'Wat zijn de openingstijden van de testlocatie?',
                    answer: `De openingstijden zijn afgestemd op de werktijden van je werkgever. Na inlog met je
gegevens, bij de keuze van een testdatum en tijd, vind je een overzicht van de precieze
openingstijden. Er is vóór aanvang van iedere werkdag of shift voldoende tijd om je te laten
testen.
Je kunt je alleen laten testen op afspraak. Een afspraak maak je via deze website. Als het
goed is, heb je alle informatie over de testprocedure ontvangen via je werkgever. Heb je
geen informatie ontvangen? Neem dan eerst contact op met je leidinggevende.`,
                    active: false,
                },
                {
                    question: 'Zijn de testen van Premium Testing wel betrouwbaar?',
                    answer: `De testen van Premium Testing worden zorgvuldig uitgevoerd door gekwalificeerde
medewerkers. Daarnaast worden alle medewerkers regelmatig door een arts getoetst op
hun werkwijze. Bij Premium Testing werkt iedereen volgens strikte protocollen voor
werkprocessen, hygiëne en ethiek. 
De testen van Premium Testing behoren tot de landelijke standaard die is afgestemd op de
voorschriften van het RIVM en de GGD.`,
                    active: false,
                },
                {
                    question: 'Werkt Premium Testing samen met de GGD?',
                    answer: `Premium Testing werkt niet samen met de GGD. Wel is er contact over protocollen,
ontwikkelingen en eventuele positieve COVID-meldingen. Bij een positieve uitslag is de arts
verplicht een melding te maken bij de GGD. Vanwege privacyredenen wordt géén melding
gedaan van de uitslag bij je werkgever.`,
                    active: false,
                },
                {
                    question: 'Welke gegevens verwerken jullie van mij?',
                    answer: `Premium Testing verwerkt alleen de benodigde gegevens gegevens om de test veilig te
kunnen afnemen. Een anonieme, persoonlijke code in combinatie met je geboortedatum is
voor ons voldoende om je te kunnen accrediteren. Om je op de hoogte te kunnen stellen
van de testuitslag verwerkt Premium Testing jouw e-mailadres en eventueel
telefoonnummer. Bij de incheck (accreditatie) aan de balie in de testfaciliteit kan om een
verificatie worden gevraagd. Dit ter controle van je geboortedatum. Verder worden geen
persoonsgegevens verwerkt.`,
                    active: false,
                },
                {
                    question: 'Hoe lang blijven mijn gegevens bij jullie bekend?',
                    answer: `Premium Testing heeft afspraken met jouw werkgever over de bewaartermijn van
persoonsgegevens. Zelf hanteert Premium Testing een bewaartermijn van maximaal 30
dagen na verstrijken van de laatste afspraakdatum.`,
                    active: false,
                },
                {
                    question: 'Ik ontvang (na 30 minuten) nog steeds geen uitslag van mijn test wat nu?',
                    answer: `Als je na 30 minuten geen uitslag hebt ontvangen, raadpleeg dan één van de medewerkers
op de testlocatie.`,
                    active: false,
                },
            ],

            faqsEN: [
                {
                    question: 'What are your opening hours?',
                    answer:
                        '<strong>Location Amsterdam</strong> <br> P3 Gelderlandplein, 1082 KZ Open from Monday to Friday from 08:00-20:00 and on Saturday and Sunday from 09:00-16:00. <br><strong>Locatie Nieuw-Vennep</strong><br> we are open from Monday to Friday from 08:00 to 16:00. Nieuw-Vennep is closed in the weekends. For companies, health care and educational institutions we can schedule a customized appointment 24/7.',
                    active: false,
                },
                {
                    question: 'Are your tests reliable?',
                    answer: `Yes they are! We use an antigen test from the company Roche (SARS-CoV-2 Antigen Rapid
                Test) and this is approved by our government (OMT). The test with the nasal swab is taken by
                medically trained personnel. The test has a specificity of 99.68% and a sensitivity of 96.52%.
                Our antibody test has a specificity of 100% for both IgG and IgM, a sensitivity of 94% and an
                accuracy of 97.6%.`,
                    active: false,
                },
                {
                    question: 'Who takes the test?',
                    answer: 'The tests are only taken by medically trained personnel who are qualified to do so.',
                    active: false,
                },
                {
                    question: 'When will I receive the test result of the (saliva) PCR test?',
                    answer:
                        'Tested before 12:00 - same day results (before 23:59/midnight). Tested after 12:00 - next day results (before 23:59/midnight).',
                    active: false,
                },
                {
                    question: 'Do you work together with the GGD?',
                    answer: `We are in contact with the GGD every day. We are obliged to report all positive cases to the
            GGD. The GGD conducts the Source and Contact Research and contacts the positive tested
            persons. All contact with the GGD is through a secure connection and is AVG-proof.`,
                    active: false,
                },
                {
                    question: 'Have another question?',
                    answer: 'You can always contact us at info@snellecoronatest.nl',
                    active: false,
                },
            ],
        }
    },

    methods: {
        showFaq(index) {
            if (this.language == 'en') {
                this.faqsEN.forEach(item => (item.active = false))
                this.faqsEN[index].active = !this.faqsEN[index].active
            } else {
                this.faqs.forEach(item => (item.active = false))
                this.faqs[index].active = !this.faqs[index].active
            }
        },
    },

    computed: {
        language() {
            return this.$store.state.language
        },
    },
}
</script>

<style lang="scss" scoped>
.faq-accordion {
    background: $color-echoblue;
    padding: $gutter * 2 $spacer;

    @include mobile {
        padding: $gutter $spacer;
    }

    span.title {
        font-size: rem(40px);
        font-weight: $bold;
        color: $color-maroon;
        text-align: center;
        display: block;
    }

    .accordion {
        max-width: 350px;
        width: 100%;
        margin: $gutter auto 0;

        .inner-wrapper {
            background: transparent;
            width: 100%;
            height: fit-content;
            position: relative;
            padding: 14px;
            border-radius: 6px;
            cursor: pointer;
            transition: background 0.2s, height 0.2s;

            svg.arrow {
                position: absolute;
                right: 10px;
                max-width: 14px;
                height: 14px;
                width: 100%;

                path {
                    fill: $color-maroon;
                }
            }

            span.question {
                font-size: rem(16px);
                font-weight: $semi-bold;
                margin-bottom: 10px;
                color: $color-tangerine;
            }

            p.answer {
                margin: 8px 0 0 0;
                font-size: rem(14px);
                color: $color-tangerine;
                opacity: 0.6;
                line-height: 1.6;
            }

            &.active {
                background: $color-white;
                transition: background 0.2s, height 0.2s;

                span.question {
                    font-weight: $semi-bold !important;
                    color: $color-maroon;
                }

                svg.arrow path {
                    fill: $color-burgundy;
                }
            }
        }
    }
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
