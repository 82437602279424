<template>
    <div class="gegevens">
        <div class="container" v-if="!success">
            <h1>{{ language == 'en' ? h1EN : h1 }}</h1>
            <div></div>
            <h2>{{ language == 'en' ? h2EN : h2 }}</h2>
        </div>

        <div class="container" v-if="!success">
            <form @submit.prevent="setUserData" v-if="!success">
                <div class="row">
                    <label for="bday" v-if="language === 'en'"
                        >{{ bdayEN }} <span class="helper">{{ bdayInfo }}</span></label
                    >
                    <label for="bday" v-else
                        >{{ bday }} <span class="helper">{{ bdayInfo }}</span></label
                    >
                    <input
                        type="text"
                        id="bday"
                        name="bday"
                        v-model="userData.bday"
                        :class="[errors.includes('bday') ? 'error' : '']"
                    />
                    <span class="error color-error" v-if="errors.includes('bday')">{{
                        language == 'en' ? errbdayEN : errbday
                    }}</span>
                </div>

                <div class="row">
                    <label for="name">{{ language == 'en' ? uanEN : uan }} </label>
                    <input
                        type="text"
                        id="uan"
                        name="uan"
                        v-model="userData.uan"
                        :class="[errors.includes('uan') ? 'error' : '']"
                    />
                    <span class="error color-error" v-if="errors.includes('uan')">{{
                        language == 'en' ? errUanEN : errUan
                    }}</span>
                </div>

                <div class="row">
                    <label for="email" v-if="language === 'en'"
                        >{{ emailEN }} <span class="helper">{{ emailInfoEN }}</span></label
                    >
                    <label for="email" v-else
                        >{{ email }} <span class="helper">{{ emailInfo }}</span></label
                    >
                    <input
                        type="text"
                        id="email"
                        name="email"
                        v-model="userData.email"
                        :class="[errors.includes('email') ? 'error' : '']"
                    />
                    <span class="error color-error" v-if="errors.includes('email')">{{
                        language == 'en' ? erremailEN : erremail
                    }}</span>
                </div>

                <div class="row">
                    <label for="mobile" v-if="language === 'en'"
                        >{{ mobileEN }} <span class="helper">{{ mobileInfoEN }}</span></label
                    >
                    <label for="mobile" v-else
                        >{{ mobile }} <span class="helper">{{ mobileInfo }}</span></label
                    >
                    <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        v-model="userData.mobile"
                        :class="[errors.includes('mobile') ? 'error' : '']"
                    />
                    <span class="error color-error" v-if="errors.includes('mobile')">{{
                        language == 'en' ? errmobileEN : errmobile
                    }}</span>
                </div>

                <div class="row">
                    <label for="mobile">{{ language == 'en' ? remarksEN : remarks }}</label>
                    <textarea id="remarks" name="remarks" v-model="userData.remarks" />
                </div>

                <hr />

                <div class="row">
                    <label class="custom-chkbx-container">
                        <span v-html="agreeToTermsEN" v-if="language === 'en'"></span>
                        <span v-html="agreeToTerms" v-else></span>
                        <input type="checkbox" v-model="requiredCheckbox" />
                        <span class="checkmark"></span>
                    </label>
                    <span class="error color-error" v-if="errors.includes('required')">{{
                        language == 'en' ? errtermsEN : errterms
                    }}</span>
                </div>
            </form>

            <Aside
                :date="storeDate"
                :btnText="btnText"
                :checkout="true"
                v-on:emitdate="setUserData"
                v-if="!success"
            ></Aside>
        </div>

        <HowDoesItWork :nobtn="true"></HowDoesItWork>
    </div>
</template>

<script>
import { api } from '@/api'
import Aside from '@/components/Aside.vue'
import HowDoesItWork from '@/components/HowDoesItWork.vue'

export default {
    name: 'Gegevens',

    components: {
        Aside,
        HowDoesItWork,
    },

    props: {
        h1: {
            type: String,
            default: 'Persoonsgegevens',
        },
        h1EN: {
            type: String,
            default: 'Personal data',
        },
        h2: {
            type: String,
            default: 'Overzicht',
        },
        h2EN: {
            type: String,
            default: 'overview',
        },

        contactInfo: {
            type: String,
            default: 'Contactgegevens',
        },
        contactInfoEN: {
            type: String,
            default: 'Contact info',
        },

        companyName: {
            type: String,
            default: 'Bedrijfsnaam',
        },
        companyNameEN: {
            type: String,
            default: 'Companyname',
        },
        uan: {
            type: String,
            default: 'Uniek anoniem nummer',
        },
        uanEN: {
            type: String,
            default: 'Unique anonymous number',
        },
        bday: {
            type: String,
            default: 'Geboortedatum',
        },
        bdayEN: {
            type: String,
            default: 'Birthday',
        },
        bdayInfo: {
            type: String,
            default: 'Format: xx-xx-xxxx',
        },
        email: {
            type: String,
            default: 'E-mailadres',
        },
        emailEN: {
            type: String,
            default: 'E-mailaddress',
        },
        emailInfo: {
            type: String,
            default: 'U ontvangt hier uw bevestigingsmail',
        },
        emailInfoEN: {
            type: String,
            default: 'You will receive your confirmation email here',
        },
        mobile: {
            type: String,
            default: 'Mobiel',
        },
        mobileEN: {
            type: String,
            default: 'Mobile',
        },
        mobileInfo: {
            type: String,
            default: 'Zo houden we u op de hoogte van de afspraak',
        },
        mobileInfoEN: {
            type: String,
            default: 'This is how we keep you informed of the appointment',
        },
        remarks: {
            type: String,
            default: 'Opmerking',
        },
        remarksEN: {
            type: String,
            default: 'Remark',
        },
        btnText: {
            type: String,
            default: 'Bevestigen',
        },
        btnTextEN: {
            type: String,
            default: 'Confirm',
        },
        agreeToTerms: {
            type: String,
            default:
                'Ik ga akkoord met de <a href="https://eenafspraakmaken.nl/terms_and_conditions.pdf" class="terms">algemene voorwaarden</a>',
        },
        agreeToTermsEN: {
            type: String,
            default:
                'I agree with the <a href="https://eenafspraakmaken.nl/terms_and_conditions.pdf" class="terms">general terms and conditions</a>',
        },
        errbday: {
            type: String,
            default: 'Graag uw geboortedatum in DD-MM-YYYY format',
        },
        errbdayEN: {
            type: String,
            default: 'Birthday must use the format DD-MM-YYYY',
        },
        erremail: {
            type: String,
            default: 'Uw e-mailadres is ongeldig',
        },
        erremailEN: {
            type: String,
            default: 'Your e-mailaddress is invalid',
        },
        errUan: {
            type: String,
            default: 'Ongeldig uniek anoniem nummer',
        },
        errUanEN: {
            type: String,
            default: 'Invalid unique anonymous numbers',
        },
        errmobile: {
            type: String,
            default: 'Uw mobiele nummer is ongeldig',
        },
        errmobileEN: {
            type: String,
            default: 'Your mobile number is invalid',
        },
        errterms: {
            type: String,
            default: 'U dient onze algemene voorwaarden te accepteren',
        },
        errtermsEN: {
            type: String,
            default: 'Accepting our General terms and conditions is required',
        },
    },

    data() {
        return {
            success: false,
            errors: [],
            requiredCheckbox: false,
            selectedProduct: {},
            validations: {
                birthday: /^(0[1-9]|[12][0-9]|3[01])[- .](0[1-9]|1[012])[- .](19|20)\d\d$/,
                email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                mobile: /^[\s0-9*#+()-]+$/,
            },
            userData: {
                uan: '',
                bday: '',
                email: '',
                mobile: '',
                remarks: '',
            },
            bullets: ['* Gegevens van degene die getest wordt'],
            bulletsEN: ['* Data of the person being tested'],
        }
    },

    computed: {
        storeDate() {
            return this.$store.state.date
        },

        language() {
            return this.$store.state.language
        },

        selectedLocation() {
            return this.$store.state.location
        },
    },

    methods: {
        checkErrors() {
            if (!this.userData.bday.match(this.validations.birthday)) {
                this.errors.push('bday')
            }
            if (!this.userData.email.match(this.validations.email)) {
                this.errors.push('email')
            }
            if (!this.userData.mobile.match(this.validations.mobile)) {
                this.errors.push('mobile')
            }
            if (!this.userData.uan.length || (this.userData.uan.length < 3 && this.userData.uan.length > 6)) {
                this.errors.push('uan')
            }
            if (!this.requiredCheckbox) {
                this.errors.push('required')
            }
        },

        setUserData() {
            this.errors = []
            this.checkErrors()

            if (this.errors.length) return

            this.$store.commit('SET_USERDATA', this.userData)

            this.$nextTick(() => {
                api.post('/confirmAppointment', {
                    userData: this.$store.state.userData,
                    dateTime: this.$store.state.date,
                    locationId: this.$store.state.location,
                    werkgever: this.$store.state.companyData.bedrijfsnaam,
                })
                    .then(response => {
                        this.$router.push({ name: 'Betaald' })
                    })
                    .catch(error => {
                        console.log(error)
                        // this.$router.push('/')
                    })
            })
        },
    },

    watch: {
        'userData.uan': {
            deep: true,

            handler(value) {
                if (/\s/.test(value)) {
                    this.userData.uan = value.split('/').join('')
                }
            },
        },

        'userData.email': {
            deep: true,

            handler(value) {
                if (/\s/.test(value)) {
                    this.userData.email = value.split(' ').join('')
                }
                this.$store.commit('SET_EMAIL', value)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.family__button__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @include tablet {
        grid-template-columns: 1fr;
    }

    .family__title {
        color: $color-burgundy;
        font-size: rem(16px);
        font-weight: $semi-bold;
    }

    .family__button {
        background: $color-white;
        width: 100%;
        height: 60px;
        text-align: center;
        margin-top: 5px;
        padding: 10px;
        outline: 0;
        border: 1px solid rgba(52, 193, 195, 0.4);
        border-radius: 6px;
        box-shadow: 0px 4px 5px rgba(57, 9, 35, 0.05);
        transition: border-color 0.2s;
        cursor: pointer;

        &.active {
            border: 2px solid $color-burgundy;
        }
    }

    cite {
        font-size: rem(14px);
        color: $color-tangerine;
        margin-top: 0;
    }
}

.gegevens {
    max-width: 1100px;
    width: 100%;
    margin: $gutter * 2 auto;

    @include desktop {
        margin: $gutter auto;
    }

    .container {
        display: grid;
        grid-template-columns: 520px auto 250px;
        grid-gap: $gutter;

        @include desktop {
            padding: 0 $spacer;
            grid-template-columns: 1fr;

            &:first-of-type {
                grid-gap: 0;
            }
        }

        form {
            background: $color-white;
            width: 100%;
            border: 1px solid rgba(52, 193, 195, 0.5);
            padding: $spacer - 4;

            ul {
                margin: 0 0 $spacer;
                padding: 0;
                list-style: none;

                li {
                    width: 100%;
                    display: block;
                    margin-bottom: 2px;
                    font-size: rem(10px);
                    color: $color-maroon;
                }
            }

            .family__helper {
                font-size: rem(10px);
                color: $color-maroon;
                display: block;
                margin-bottom: 10px;
            }

            .row {
                width: 100%;
                display: block;
                margin-bottom: $spacer;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &.col-3 {
                    display: grid;
                    grid-template-columns: calc(50% - 30px) 25% 25%;
                    grid-gap: 15px;
                }

                &.radios {
                    max-width: 200px;
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    grid-gap: 10px;
                    position: relative;

                    input,
                    label {
                        display: inline-block;
                    }

                    label {
                        margin-left: 5px;
                        vertical-align: bottom;
                    }

                    [type='radio']:checked,
                    [type='radio']:not(:checked) {
                        position: absolute;
                        opacity: 0;
                    }
                    [type='radio']:checked + label,
                    [type='radio']:not(:checked) + label {
                        position: relative;
                        padding-left: 28px;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        color: $color-maroon;
                        font-weight: $semi-bold;
                    }
                    [type='radio']:checked + label:before,
                    [type='radio']:not(:checked) + label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid $color-burgundy;
                        border-radius: 100%;
                        background: #fff;
                    }
                    [type='radio']:checked + label:after,
                    [type='radio']:not(:checked) + label:after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: $color-burgundy;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        border-radius: 100%;
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                    }
                    [type='radio']:not(:checked) + label:after {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                    [type='radio']:checked + label:after {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }

                span.subtitle {
                    font-weight: $bold;
                }
            }

            label {
                font-size: rem(14px);
                font-weight: $semi-bold;
                color: $color-maroon;
                display: block;
                margin-bottom: 3px;

                &.terms {
                    font-size: rem(10px);
                    color: $color-burgundy;
                    opacity: 1;
                    display: inline-block;
                }

                span.helper {
                    font-size: rem(10px);
                    float: right;
                }
            }

            input[type='text'],
            input[type='email'],
            select,
            textarea {
                width: 100%;
                height: 40px;
                outline: 0;
                padding: 0 10px;
                color: $color-maroon;
                border: 1px solid rgba(52, 193, 195, 0.4);
                border-radius: 6px;
                box-shadow: 0px 4px 5px rgba(57, 9, 35, 0.05);
                transition: border-color 0.2s;

                &:focus {
                    border-color: rgba(52, 193, 195, 0.85);
                    transition: border-color 0.2s;
                }
            }

            textarea {
                height: 125px;
                resize: none;
                font-size: rem(14px);
                font-family: $Signika;
                padding-top: 12px;
            }

            .custom-chkbx-container {
                display: block;
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                font-size: rem(10px);
                margin-bottom: 0;

                &.reason {
                    height: 30px;

                    @include mobile {
                        height: 44px;

                        .checkmark {
                            top: 4px !important;
                        }
                    }

                    span:not(.checkmark) {
                        font-size: 12px;
                        font-weight: normal;
                    }
                }

                span:not(.checkmark) {
                    display: block;
                    position: relative;
                    top: 3px;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 18px;
                    width: 18px;
                    border: 1px solid $color-burgundy;
                    border-radius: 4px;

                    &:after {
                        content: '';
                        position: absolute;
                        display: none;
                        left: 5px;
                        top: 3px;
                        width: 3px;
                        height: 7px;
                        border: solid $color-burgundy;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }

                input:checked ~ .checkmark:after {
                    display: block;
                }
            }
        }
    }

    span.error {
        font-size: rem(14px);
        display: block;
        margin-top: 5px;
    }

    input.error {
        border-color: $color-error !important;
    }
}
</style>
