<template>
    <section class="faq">
        <h3 v-if="language === 'en'">{{ h3EN }}</h3>
        <h3 v-else>{{ h3 }}</h3>

        <ul v-if="language === 'en'">
            <li v-for="item in questionsEN" :key="item.title">
                {{ item.title }}
                <span v-html="item.sub"></span>
            </li>
        </ul>
        <ul v-else>
            <li v-for="item in questions" :key="item.title">
                {{ item.title }}
                <span v-html="item.sub"></span>
            </li>
        </ul>

        <Button></Button>
    </section>
</template>

<script>
import Button from '@/components/AppointmentBtn.vue'

export default {
    name: 'FAQ',

    components: {
        Button,
    },

    props: {
        h3: {
            type: String,
            default: 'Veelgestelde vragen',
        },
        h3EN: {
            type: String,
            default: 'Frequently asked questions',
        },
    },

    data() {
        return {
            questions: [
                {
                    title: 'Wat zijn de openingstijden van de testlocatie?',
                    sub: `De openingstijden zijn afgestemd op de werktijden van je werkgever. Na inlog met je
gegevens, bij de keuze van een testdatum en tijd, vind je een overzicht van de precieze
openingstijden. Er is vóór aanvang van iedere werkdag of shift voldoende tijd om je te laten
testen.
Je kunt je alleen laten testen op afspraak. Een afspraak maak je via deze website. Als het
goed is, heb je alle informatie over de testprocedure ontvangen via je werkgever. Heb je
geen informatie ontvangen? Neem dan eerst contact op met je leidinggevende.`,
                },
                {
                    title: 'Zijn de testen van Premium Testing wel betrouwbaar?',
                    sub: 'Al onze locaties zijn 7 dagen per week geopend van 08:00 tot 20:00.',
                },
                {
                    title: 'Zijn jullie sneltesten betrouwbaar?',
                    sub: `De testen van Premium Testing worden zorgvuldig uitgevoerd door gekwalificeerde
medewerkers. Daarnaast worden alle medewerkers regelmatig door een arts getoetst op
hun werkwijze. Bij Premium Testing werkt iedereen volgens strikte protocollen voor
werkprocessen, hygiëne en ethiek. 
De testen van Premium Testing behoren tot de landelijke standaard die is afgestemd op de
voorschriften van het RIVM en de GGD.`,
                },
                {
                    title: 'Werkt Premium Testing samen met de GGD?',
                    sub: `Premium Testing werkt niet samen met de GGD. Wel is er contact over protocollen,
ontwikkelingen en eventuele positieve COVID-meldingen. Bij een positieve uitslag is de arts
verplicht een melding te maken bij de GGD. Vanwege privacyredenen wordt géén melding
gedaan van de uitslag bij je werkgever.`,
                },
                {
                    title: 'Welke gegevens verwerken jullie van mij?',
                    sub: `Premium Testing verwerkt alleen de benodigde gegevens gegevens om de test veilig te
kunnen afnemen. Een anonieme, persoonlijke code in combinatie met je geboortedatum is
voor ons voldoende om je te kunnen accrediteren. Om je op de hoogte te kunnen stellen
van de testuitslag verwerkt Premium Testing jouw e-mailadres en eventueel
telefoonnummer. Bij de incheck (accreditatie) aan de balie in de testfaciliteit kan om een
verificatie worden gevraagd. Dit ter controle van je geboortedatum. Verder worden geen
persoonsgegevens verwerkt.`,
                },
                {
                    title: 'Hoe lang blijven mijn gegevens bij jullie bekend?',
                    sub: `Premium Testing heeft afspraken met jouw werkgever over de bewaartermijn van
persoonsgegevens. Zelf hanteert Premium Testing een bewaartermijn van maximaal 30
dagen na verstrijken van de laatste afspraakdatum.`,
                },
                {
                    title: 'Ik ontvang (na 30 minuten) nog steeds geen uitslag van mijn test wat nu?',
                    sub: `Als je na 30 minuten geen uitslag hebt ontvangen, raadpleeg dan één van de medewerkers
op de testlocatie.`,
                },
            ],
            questionsEN: [
                {
                    title: 'Do you have questions about Testing for Departure?',
                    sub: `Call <a href="tel:+31629146482">+31 6 29146482</a>`,
                },
                {
                    title: 'What are the opening hours?',
                    sub: 'All our locations are open 7 days a week from 08:00 to 20:00. ',
                },
                {
                    title: 'When will I receive the test result of the (saliva) PCR test?',
                    sub: `Tested before 12:00 - same day results (before 23:59/midnight). Tested after 12:00 - next day results (before 23:59/midnight).`,
                },
                {
                    title: 'How reliable are the tests? ',
                    sub: `We're using a Roche (SARS-CoV-2 Antigen Rapid
                Test) antigen test, which is approved by our government (OMT). The test is taken by
                medically trained personnel. The test has a specificity of 99.68% and a sensitivity of 96.52%.
                Our antibody test has a specificity of 100% for both IgG and IgM, a sensitivity of 94% and an
                accuracy of 97.6%.`,
                },
                {
                    title: 'Who will be performing the test?',
                    sub: 'The tests will be taken by medically qualified personnel.',
                },
                {
                    title: 'Where is the test location?',
                    sub: `<strong>Location Amsterdam</strong> <br> <a href='https://www.google.com/maps/dir//P3+Gelderlandplein,+1082+KZ+Amsterdam' target='_blank'>P3 Gelderlandplein, 1082 KZ Amsterdam</a> <br>
          <a href='https://www.google.com/maps/dir//Daniel+Goedkoopstraat+23a+1096+BD+Amsterdam' target='_blank'>Daniël Goedkoopstraat 23a, 1096 BD Amsterdam</a> <br><br>
					 <br><strong>Location Nieuw-Vennep</strong> <br> <a href='https://www.google.com/maps/place/Pondweg+7+2153+PK+Nieuw-Vennep' target='_blank'>Pondweg 7, 2153 PK Nieuw-Vennep</a>.<br><br>`,
                },
                {
                    title: 'What are the costs for the rapid tests?',
                    sub: `The Saliva rapid test costs € 30.<br>The Antigen rapid test costs € 25.<Br>The Antibody rapid test costs € 29.`,
                },
                {
                    title: 'What are the costs for the PCR tests?',
                    sub: `The cost is € 85 for the PCR test + travel statement.<br>PCR saliva test + travel statement costs € 75.<br>The last Minute PCR + travel statement costs € 95.`,
                },
                {
                    title: 'Are you connected to the GGD?',
                    sub: `
            We are in contact with the GGD every day. We are obliged to report all positive tests to the
            GGD. The GGD conducts the Source and Contact Research and will contact the positive tested
            persons. All contact with the GGD is via a secured connection and is AVG-proof.
          `,
                },
                {
                    title: 'How can you be reached?',
                    sub:
                        "Please contact us via <a href='mailto:info@snellecoronatest.nl'>info@snellecoronatest.nl</a>.",
                },
                {
                    title: 'Is it possible to be tested on location?',
                    sub:
                        "Sure you can. Please contact <a href='mailto:bedrijven@snellecoronatest.nl'>bedrijven@snellecoronatest.nl</a> for more information.",
                },
                {
                    title: 'Will I receive a travel statement after taking the test?',
                    sub:
                        'You will only receive a travel statement after taking the PCR Test + Travel Statement or after taking the PCR Saliva Spit Test + Travel Statement.',
                },
                {
                    title: 'Do you have other questions?',
                    sub:
                        "You can always contact us at <a href='mailto:info@snellecoronatest.nl'>info@snellecoronatest.nl</a>.",
                },
                {
                    title: 'Can I cancel or reschedule my appointment?',
                    sub:
                        'Yes, you can. If the appointment is cancelled at least 24 hours in advance, we only charge €10,- administrative fee. If you do not show up for the scheduled appointment or wish to cancel within 24 hours of the appointment taking place, we reserve the right to charge you the full amount. Rescheduling an appointment is free of charge.',
                },
            ],
        }
    },

    computed: {
        language() {
            return this.$store.state.language
        },
    },
}
</script>

<style lang="scss">
section.faq {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: $gutter * 2 0;
    text-align: center;

    @include desktop {
        padding: $gutter 0;
    }

    h3 {
        font-size: rem(24px);
        font-weight: $semi-bold;
        color: $color-burgundy;
        margin: 0 0 $gutter 0;
        padding: 0;
    }

    ul {
        max-width: 810px;
        width: 100%;
        margin: 0 auto;
        padding: 0 $spacer;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include tablet {
            flex-direction: column;
        }

        li {
            font-weight: $semi-bold;
            font-size: rem($base-font-size);
            color: $color-maroon;
            position: relative;
            margin-bottom: $gutter;

            &:last-of-type {
                margin-bottom: 0;
            }

            span {
                font-size: rem(14px);
                font-weight: $normal;
                color: $color-tangerine;
                opacity: 0.8;
                display: block;
                margin-top: 10px;

                a {
                    color: $color-burgundy;
                }
            }
        }
    }

    a.btn {
        margin-top: $gutter;
    }
}
</style>
